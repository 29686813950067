<template>
  <div class="all">
    <Navbar class="tablet-hidden mobile-hidden" />
    <div class="page">
      <Header />

      <div v-show="loading" class="flex-center mt-3">
        <div class="loading2"></div>
      </div>
      <div class="home container">
        <div class="grid">
          <div v-show="!loading" class="info-holder">
            <div v-show="subscritionStatus" class="item">
              <div>
                <p>
                  {{ t("Plano contratado:") }}
                </p>
                <p style="margin-top: 4px">
                  <b>{{ subscrition ? subscrition.name : "" }}</b>
                </p>
              </div>
              <p class="value">
                {{ subscrition ? subscrition.price / 100 : "" | currency }} /
                {{ t(getFrequencyName()) }}
              </p>
            </div>
            <div v-show="subscritionStatus" class="item">
              <p>Status:</p>
              <p class="value">{{ t(subscritionStatus) }}</p>
            </div>
            <div v-show="subscrition && getNextPayment()" class="item">
              <p>
                {{
                  subscrition && subscrition.active
                    ? t("Próximo Pagamento:")
                    : t("Acesso até:")
                }}
              </p>
              <p class="value">{{ getNextPayment() }}</p>
            </div>

            <p class="info">
              {{
                t(
                  "Caso você deseje alterar o seu plano, ou os dados do seu cartão de crédito, você precisará primeiro cancelar o seu plano atual. Você ainda poderá usar o aplicativo pelos dias referentes ao seu último pagamento. Após passarem estes dias, você poderá escolher um novo plano e/ou alterar os dados do seu cartão."
                )
              }}
            </p>

            <p class="info red">
              {{
                t(
                  "Caso você tenha realizado algum pagamento há mais de 48 horas e ainda não tenha sido processado, por favor, verifique seu e-mail para verificar o que aconteceu ou entre em contato pelo e-mail"
                )
              }}
              <a href="mailto:support@mysm.com.br">support@mysm.com.br</a>.
            </p>
            <transition enter-active-class="animated fadeInUp">
              <div
                v-show="
                  !loading &&
                  subscritionStatus &&
                  subscritionStatus !== 'Cancelado'
                "
                class="btn-holder"
                style="animation-delay: 1000ms"
                @click="cancelSubscrition()"
              >
                <div class="btn btn-red cancel">
                  <font-awesome-icon
                    v-show="!saving"
                    :icon="['fas', 'times']"
                  />
                  <span v-show="!saving">{{ t("Cancelar assinatura") }}</span>
                  <div v-show="saving" class="loading2"></div>
                </div>
              </div>
            </transition>
            <transition enter-active-class="animated fadeInUp">
              <router-link to="/cadastro?renew=y">
                <div
                  v-show="
                    !loading &&
                    (!subscritionStatus || subscritionStatus === 'Cancelado')
                  "
                  class="btn-holder"
                  style="animation-delay: 500ms"
                >
                  <div class="btn">
                    <span>{{ t("Ativar Assinatura") }}</span>
                  </div>
                </div>
              </router-link>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "@/http";
import Navbar from "../components/Navbar";
import Header from "../components/Header";
import authentication from "../util/authentication";
import platform from "../util/platform";
export default {
  components: {
    Navbar,
    Header,
  },
  data() {
    return {
      subscritionStatus: null,
      subscrition: null,
      loading: false,
      saving: false,
      user: {
        professionalName: "",
        image: "",
        imageLastModified: "",
      },
      warningMessage: "",
    };
  },
  created() {
    this.getPlan();
  },
  methods: {
    getPlan() {
      var receipt = "";
      this.loading = true;
      http
        .post("User/Subscription", { receipt }, authentication.getHeaders())
        .then((res) => {
          if (res.data.id) {
            this.subscrition = res.data;
          }
          this.setPlanStatus();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getFrequencyName() {
      if (this.subscrition && this.subscrition.planDetails) {
        switch (this.subscrition.planDetails.interval) {
          case 1:
            return "mês";
          case 6:
            return "semestre";
          case 12:
            return "ano";
          default:
            return "mês";
        }
      }
    },
    getNextPayment() {
      if (
        !this.subscrition ||
        !this.subscrition.planDetails ||
        !this.subscrition.planDetails.accessLimit
      )
        return null;

      var now = new Date();
      var accessLimit = new Date(this.subscrition.planDetails.accessLimit);

      if (this.subscrition.active && accessLimit <= now) {
        return null;
      } else {
        return this.$moment(accessLimit).format("DD/MM/YYYY");
      }
    },
    setPlanStatus() {
      if (!this.subscrition) return;
      var now = new Date();
      var planActive = this.subscrition.active || null;
      var planAccessLimit =
        new Date(this.subscrition.planDetails.accessLimit) || new Date(null);

      if (planActive) {
        if (planAccessLimit > now) {
          this.subscritionStatus = "Pagamento Aprovado";
        } else {
          this.subscritionStatus = "Aguardando Pagamento";
        }
      } else {
        this.subscritionStatus = "Cancelado";
      }
    },
    cancelSubscrition() {
      alert("Em breve");
      return;
      this.loading = true;
      http
        .delete(
          "Subscription/" + this.subscrition.id,
          authentication.getHeaders()
        )
        .then((res) => {
          if (res.data.statusCode === 200) {
            this.subscrition.active = 0;
            this.setPlanStatus();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  position: relative;
  padding-bottom: 45px;
}

.home.container {
  width: 90%;
  max-width: initial;
}

.grid {
  position: relative;
  width: 100%;
  max-width: 580px;
  display: grid;
  grid-template-columns: 1fr;
  margin: 25px 0 0 0;
  gap: 0 3.5rem;
  @media screen and (max-width: 1139px) {
    display: block;
  }
}

.info-holder {
  position: relative;
  box-shadow: 0 25px 35px rgba(18, 18, 44, 0.2);
  border-radius: 25px;
  padding: 25px 25px 35px 25px;
  margin-top: 25px;
  .btn-holder {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px dashed #ddd;
    padding-top: 35px;
    margin-top: 35px;
    .btn-red {
      background: var(--red1);
      border-color: var(--red1);
      transition: background-color 0.25s ease;
      &:hover {
        background-color: hsl(0, 87%, 40%);
      }
      span,
      svg {
        color: #fff;
      }
    }
  }
}

.item {
  position: relative;
  width: 100%;
  min-height: 60px;
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px dashed #ddd;
  @media screen and (max-width: 720px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: 70px;
    gap: 4px;
  }
  p {
    font-size: 1.15em;
    &.value {
      font-size: 1.2em;
      color: var(--light-blue);
      text-align: right;
    }
    b {
      font-size: 1.1em;
      @media screen and (max-width: 720px) {
        display: block;
      }
    }
  }
}

.info {
  font-size: 1.15em;
  text-align: center;
  margin: 35px auto 0 auto;
  line-height: 1.5em;
  &.red {
    color: var(--red1);
  }
  a {
    font-size: 1em;
    font-family: fontMedium;
  }
}
</style>
